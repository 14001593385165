import Vue from 'vue'
import VueRouter from 'vue-router'
import DataService from "../services/DataService";
import common from '../assets/common/vue-common.js'
import Unauthorized from '../views/auth/Unauthorized.vue'
import { useloggedUserInfo } from "../stores/UserInfo";
import authservice from '../services/authservice.js';
Vue.use(VueRouter)
const routes = [
    {
        path: '/Ticketing/Dashboard',
        name: 'DashboardLink',
        meta: {
            isCheckPrivilege: false
        }
    },
    {
        path: "/Ticketing/add/:id?",
        name: "add",
        component: () => import("@/views/Ticketing/addWithCustomLayout.vue"),
        meta: {
            title: 'Add',
            breadcrumb: [
                { name: "Ticketing", link: "/Ticketing/Index" },
                { name: "Manage" }
            ]
        }
    },
    {
        path: '/unauthorized',
        name: 'Unauthorized',
        component: Unauthorized
    },
    {
        path: "/Ticketing/Index",
        name: "TicketingList",
        component: () => import("@/views/Ticketing/List.vue"),
        meta: {
            IsAssigned: 1,
            title: 'Ticketing',
            breadcrumb: [
                { name: "Ticketing" }
            ]
        }
    },
    {
        path: "/Ticketing/AcceptancePending",
        name: "PendingAcceptanceList",
        component: () => import("@/views/PendingAcceptance/PendingAcceptanceList.vue"),
        meta: {
            IsAssigned: 1,
            title: 'Ticketing',
            breadcrumb: [
                { name: "Acceptance Pending" }
            ]
        }
    },
    {
        path: "/Ticketing/ViewHistory",
        name: "ViewHistoryList",
        component: () => import("@/views/PendingAcceptance/ViewHistory.vue"),
        meta: {
            controllerName: "Ticketing",
            actionName: "AcceptancePending",
            IsAssigned: 1,
            title: 'Ticketing',
            breadcrumb: [
                { name: "History" }
            ]
        }
    },
    {
        path: "/Ticketing/Unassigned",
        name: "TicketingList",
        component: () => import("@/views/Ticketing/List.vue"),
        meta: {
            IsAssigned: 0,
            title: 'Unassigned',
            breadcrumb: [
                { name: "Ticketing", link: "/Ticketing/Index" },
                { name: "Unassigned" }
            ]
        }
    },
    {
        path: "/Ticketing/ResourceTypeDetail/:ResourceTypeId/:ResourceTypeName",
        name: "ResourceTypeDetail",
        component: () => import("@/views/ResourceType/ResourceTypeDetail.vue"),
        meta: {
            title: 'Resource Type Listing',
            breadcrumb: [
                { name: "Resource Type Listing", link: "/Ticketing/ResourceType" },
                { name: "Detail" }
            ]
        }
    },
    {
        path: "/Ticketing/Manage/:id",
        name: "Details",
        component: () => import("@/views/Ticketing/Detail.vue"),
        meta: {
            userguide: 'UgTicketDetail',
            title: 'Details',
            breadcrumb: [
                { name: "Ticketing", link: "/Ticketing/Index" },
                { name: "Details" }
            ]
        }
    },
    {
        path: "/RuleEngine/Index",
        name: "RuleEngine",
        component: () => import("@/views/RuleEngine/List.vue"),
        meta: {
            title: 'Rule Engine',
            breadcrumb: [
                { name: "Rule Engine" }
            ]
        }
    },
    {
        path: "/RuleEngine/Add/:id",
        name: "AddEditRuleEngine",
        component: () => import("@/views/RuleEngine/Manage.vue"),
        meta: {
            title: 'Add Rule',
            breadcrumb: [
                { name: "Rule Engine", link: "/RuleEngine/Index" },
                { name: "Manage" }
            ]
        },

    },
    {
        path: "/Ticketing/WorkOrderListing",
        name: "WorkOrderListing",
        component: () => import("@/views/WorkOrder/WorkOrderListing.vue"),
        meta: {
            title: 'Work Order',
            breadcrumb: [
                { name: "Work Order" },
                { name: "Work Order Listing" }
            ]
        },

    },

    {
        path: "/Ticketing/Group",
        name: "GroupListing",
        component: () => import("@/views/Group/GroupListing.vue"),
        meta: {
            title: 'Ticket Group',
            breadcrumb: [
                { name: "Ticket Group" }
            ]
        }
    },
    {
        path: "/Ticketing/IncidentTypeListing",
        name: "IncidentTypeListing",
        component: () => import("@/views/IncidentType/IncidentTypeListing.vue"),
        meta: {
            title: 'Incident Type',
            breadcrumb: [
                { name: "Incident Type Listing" }
            ]
        },

    },
    {
        path: "/Ticketing/worktypeListing",
        name: "WorkTypeListing",
        component: () => import("@/views/WorkType/worktypeListing.vue"),
        meta: {
            title: 'Work Type',
            breadcrumb: [
                { name: "Work Type Listing" }
            ]
        },
    },

    {
        path: "/Ticketing/MapStatus",
        name: "WorkTypeListing",
        component: () => import("@/views/MapStatus/MapStatusListing.vue"),
        meta: {
            title: 'Map Status',
            breadcrumb: [
                { name: "Map Status Listing" }
            ]
        },
    },
    {
        path: "/Ticketing/TicketTypeList",
        name: "TicketTypeList",
        component: () => import("@/views/TicketType/TicketTypeListing.vue"),
        meta: {
            title: 'Ticket Type',
            breadcrumb: [
                { name: "Ticket Type" },
                { name: "Ticket Type" }
            ]
        }

    }, {
        path: "/FormBuilder/Index",
        name: "FormBuilderListing",
        component: () => import("@/views/FormBuilder/FormBuilderListing.vue"),
        meta: {
            title: 'Form Builder',
            breadcrumb: [
                { name: "Form Builder" },
                { name: "Form Builder Listing" }
            ]
        },

    },
    {
        path: "/FormBuilder/View/:id",
        name: "FormBuilderView",
        component: () => import("@/views/FormBuilder/ViewFormById.vue"),
        meta: {
            title: 'View Form',
            breadcrumb: [
                { name: "Form Builder" },
                { name: "View Form" }
            ]
        },

    },
    {
        path: "/FormBuilder/Manage/:FormId/:ModuleId/:SubModuleId/:FormName/:FormType/:Desc",
        name: "ManageFormBuilder",
        component: () => import("@/views/FormBuilder/AddEditFormBuilder.vue"),
        meta: {
            title: 'Form Builder',
            breadcrumb: [
                { name: "Form Builder " },
                { name: "Form Builder Listing" }
            ]
        },

    },


    {
        path: "/Ticketing/crew",
        name: "CrewListing",
        component: () => import("@/views/crew/CrewListing.vue"),
        meta: {
            title: 'crew',
            breadcrumb: [
                { name: "Ticketing", link: "/Ticketing/Index" },
                { name: "Crew" }
            ]
        },
    },
    {
        path: "/Ticketing/TicketTypeList",
        name: "TicketTypeList",
        component: () => import("@/views/TicketType/TicketTypeListing.vue"),
        meta: {
            title: 'Ticket Type',
            breadcrumb: [
                { name: "Ticket Type" },
                { name: "Ticket Type" }
            ]
        },
    },
    {
        path: "/Ticketing/ResourceType",
        name: "ResourceTypeList",
        component: () => import("@/views/ResourceType/ResourceTypeListing.vue"),
        meta: {
            title: 'Field Services Management Resource Type',
            breadcrumb: [
                { name: "Resource Type Listing" }
            ]
        },
    },
    {
        path: "/Ticketing/MapExtend",
        name: "MapExtendListing",
        component: () => import("@/views/MapExtend/MapExtendListing.vue"),
        meta: {
            title: 'Map Extend',
            breadcrumb: [
                { name: "Map Extend" },
                { name: "Map Extend Listing" }
            ]
        }
    },
    {
        path: "/Ticketing/MapConfig",
        name: "MapConfigListing",
        component: () => import("@/views/MapConfig/MapConfigListing.vue"),
        meta: {
            title: 'Map Config',
            breadcrumb: [
                { name: "Ticketing" },
                { name: "Manage Map Configuration" }
            ]
        }
    },
    {
        path: "/Ticketing/Chain",
        name: "Ticket Service Board",
        component: () => import("@/views/Chain/ChainListing.vue"),
        meta: {
            title: 'Ticket Service Board',
            breadcrumb: [
                { name: "Ticket Service Board" }
            ]
        },
    },
    {
        path: "/Ticketing/AddChain/:id",
        name: "AddServiceBoard",
        component: () => import("@/views/Chain/AddChain.vue"),
        meta: {
            title: 'Ticket Service Board',
            breadcrumb: [
                { name: "Ticket Service Board", link: "/Ticketing/Chain" },
                { name: "Manage" }
            ]
        },
    },

    {
        path: "/Ticketing/ChannelConfigurationListing",
        name: "ChannelConfiguration",
        component: () => import("@/views/ChannelConfiguration/ChannelConfigurationlisting.vue"),
        meta: {
            title: 'ChannelConfiguration',
            breadcrumb: [
                { name: "My Dashboard" }
            ]
        },
    },
    {
        path: "/Ticketing/Zone",
        name: "Zone Management",
        component: () => import("@/views/Zone/ZoneListing.vue"),
        meta: {
            title: 'Zone Management',
            breadcrumb: [
                { name: "Ticketing" },
                { name: "Zone Management" }
            ]
        },
    },
    {
        path: "/Ticketing/TransformPending",
        name: "Transform Pending",
        component: () => import("@/views/TransformPending/TransformPendingListing.vue"),
        meta: {
            title: 'Transform Pending',
            breadcrumb: [
                { name: "Ticketing" },
                { name: "Transform Pending" }
            ]
        },
    },
    {
        path: "/TicketReport/Index",
        name: "TicketReport",
        component: () => import("@/views/TicketReport/Index.vue"),
        meta: {
            title: 'Ticket Report',
            breadcrumb: [
                { name: "Ticketing", link: "/Ticketing/Index" },
                { name: "Ticket Report" }
            ]
        },
    },
    {
        path: "/Ticketing/CrewDetail/:id",
        name: "CrewDetail",
        component: () => import("../views/crew/FsmCrewDetails.vue"),
        meta: {
            title: 'Crew Detail',
            breadcrumb: [
                { name: "Ticketing", link: "/Ticketing/Index" },
                { name: "Crew Detail" }
            ]
        },
    },
    {
        path: "/Ticketing/WorktypeDetail/:id",
        name: "WorkTypeDetail",
        component: () => import("../views/WorkType/WorktypeDetail.vue"),
        meta: {
            title: 'WorkType Detail',
            breadcrumb: [
                { name: "Work Type Listing", link: "/Ticketing/Worktypelisting" },
                { name: "Detail" }
            ]
        },
    },
    {
        path: "/ServiceAppointment/ViewSADetails/:id",
        name: "ViewSADetails",
        component: () => import("../views/ServiceAppointment/ViewSADetails.vue"),
        meta: {
            title: 'Service Appointment Detail',
            breadcrumb: [
                { name: "Service Appointment", link: "/ServiceAppointment/index" },
                { name: "Detail" }
            ]
        },
    },
    {
        path: "/ServiceAppointment/ManageServiceAppointment/:id",
        name: "ManageServiceAppointment",
        component: () => import("../views/ServiceAppointment/ManageServiceAppointment.vue"),
        meta: {
            title: 'Manage ServiceAppointment',
            breadcrumb: [
                { name: "Service Appointment", link: "/ServiceAppointment/Index" },
                { name: "Manage" }
            ]
        },
    },
    {
        path: "/Ticketing/AddGroup/:id?",
        name: "AddGroup",
        component: () => import("../views/Group/AddGroup.vue"),
        meta: {
            userguide: 'UserGuideAddTicketGroup',
            title: 'Manage Group',
            breadcrumb: [
                { name: "Ticket Group", link: "/Ticketing/Group" },
                { name: "Manage" }
            ]
        },
    },

    {
        path: "/ServiceAppointment/Index",
        name: "ServiceAppointmentListing",
        component: () => import("@/views/ServiceAppointment/ServiceAppointmentList.vue"),
        meta: {
            title: 'Service Appointment',
            checkPrivilege: false,
            breadcrumb: [
                { name: "Service Appointment" },

            ]
        },
    },
    // {
    //     path: "/ServiceAppointment/Dummy",
    //     name: "ServiceAppointmentListingDummy",
    //     component: () => import("@/views/ServiceAppointment/dummy.vue"),
    //     meta: {
    //         title: 'Service Appointment',
    //         checkPrivilege: false,
    //         breadcrumb: [
    //             { name: "Service Appointment"},

    //         ]
    //     },
    // },
    {
        path: "/Ticketing/ManageServiceAppointment/",
        name: "ManageServiceAppointment",
        component: () => import("../views/ServiceAppointment/ManageServiceAppointment.vue"),
        meta: {
            title: 'Manage ServiceAppointment',
            breadcrumb: [
                { name: "Service Appointment", link: "/ServiceAppointment/Index" },
                { name: "Manage" }
            ]
        },
    },
    {
        path: "/Ticketing/Policy/",
        name: "TicketingPolicy",
        component: () => import("../views/TicketingPolicy/index.vue"),
        meta: {
            title: 'Manage SLA',
            breadcrumb: [
                { name: "Company Setup" },
                { name: "SLA" }
            ]
        },
    },
    {
        path: "/Ticketing/addpolicy/:id",
        name: "addpolicy",
        component: () => import("../views/TicketingPolicy/addpolicy.vue"),
        meta: {
            title: 'Update SLA',
            userguide: 'UgAddSLATarget',
            breadcrumb: [
                { name: "Company Setup" },
                { name: "SLA", link: "/Ticketing/Policy" },
                { name: "Manage SLA" }
            ]
        },
    },
    {
        path: "/Ticketing/TechTracking/",
        name: "TechTracking",
        component: () => import("@/views/TechTracking/TechTracking.vue"),
        meta: {
            title: 'Tech Tracking',
            breadcrumb: [
                { name: "Ticketing" },
                { name: "Tech Tracking" }
            ]
        },
    },
    {
        path: "/Ticketing/WorkOrderDetail/:id/:ticket_id",
        name: "WorkOrderDetail",
        component: () => import("@/views/WorkOrder/WorkOrderDetail.vue"),
        meta: {
            title: 'Field Service Management Work Type',
            breadcrumb: [
                { name: "Ticketing", link: "/Ticketing/Index" },
                { name: "Work Order", link: "/Ticketing/WorkOrderListing" },
                { name: "Detail" }
            ]
        },
    },
    {
        path: "/Ticketing/ManageLayout",
        name: "ManageLayout",
        component: () => import("@/views/ManageLayout/ManageLayout.vue"),
        meta: {
            title: 'Manage Layout',
            breadcrumb: [
                { name: "Ticketing", link: "/Ticketing/Index" },
                { name: "Layout" }
            ]
        },
    },
    {
        path: "/Ticketing/AdvancedSetup",
        name: "AdvancedSetup",
        component: () => import("@/views/AdvancedSetup/TicketingAdvanceSetup.vue"),
        meta: {
            title: 'Advanced Setup',
            breadcrumb: [
                { name: "Ticketing" },
                { name: "Ticketing Advanced Setup" }
            ]
        }
    },
    {
        path: "/ReplyTemplate/Index",
        name: "ReplytemplateListing",
        component: () => import("@/views/Replytemplate/Replytemplate.vue"),
        meta: {
            title: 'Manage Canned Reply'
        }
    },
    {
        path: "/KnowledgeBase/Index",
        name: "KnowledgebaseEndUser",
        component: () => import("@/views/Knowledgebase/Index.vue"),
        meta: {
            title: 'Knowledge Base',
            //checkPrivilege: false,
            breadcrumb: [
                { name: "Knowledge Base" }
            ]
        }
    },
    {
        path: "/KnowledgeBase/ManageCategory",
        name: "ManageCategory",
        component: () => import("@/views/Knowledgebase/ManageCategory.vue"),
        meta: {
            title: 'Knowledge Base',
           // checkPrivilege: false,
            breadcrumb: [
                { name: "Knowledge Base" ,link:"/KnowledgeBase/Index" },
                { name: "Manage Category" }
            ]
        }
    },
    {
        path: "/KnowledgeBase/ManageArticle",
        name: "KnowledgebaseCAUser",
        component: () => import("@/views/Knowledgebase/ManageArticle.vue"),
        meta: {
            title: 'Manage Articles',
            //checkPrivilege: false,
            breadcrumb: [
                { name: "Knowledge Base" },
                { name: "Article" }
            ]
        }
    },
    {
        path: "/KnowledgeBase/CreateArticle/:id",
        name: "CreateArticle",
        component: () => import("@/views/Knowledgebase/CreateArticle.vue"),
        meta: {
            title: 'Add Articles',
            //  checkPrivilege:false,
            breadcrumb: [
                { name: "Knowledge Base" ,link: "/KnowledgeBase/Index" },
                { name: "Article",link: "/KnowledgeBase/ManageArticle"},
                { name: "Manage" }
            ]
        }
    },
    {
        path: "/KnowledgeBase/ArticleDetails/:id",
        name: "ArticleDetails",
        component: () => import("@/views/Knowledgebase/ArticleDetails.vue"),
        meta: {
            controllerName: "KnowledgeBase",
            actionName: "ManageArticle",
            title: 'Details',
            breadcrumb: [
                { name: "KnowledgeBase", link: "/KnowledgeBase/Index" },
                { name: "Article", link: "/KnowledgeBase/ManageArticle" },
                { name: "Details" }
            ]
        }
    },
    {
        path: "/KnowledgeBase/KnowledgebaseArticleDetails/:id",
        name: "KnowledgebaseArticleDetails",
        component: () => import("@/views/Knowledgebase/KnowledgebaseArticleDetails.vue"),
        meta: {
            controllerName: "KnowledgeBase",
            actionName: "ManageArticle",
            title: 'Details',
            breadcrumb: [
                { name: "KnowledgeBase", link: "/KnowledgeBase/Index" },
                { name: "Article", link: "/KnowledgeBase/ManageArticle" },
                { name: "Details" }
            ]
        }
    },
    {
        path: "/Ticketing/ServiceListing",
        name: "ServiceListing",
        component: () => import("@/views/Service/ServiceListing.vue"),
        meta: {
            title: 'Service',
            // checkPrivilege: false,
            breadcrumb: [
                { name: "Service Listing" }
            ]
        },
    },
    {
        path: "/Ticketing/ImportService",
        name: "ImportService",
        component: () => import("@/views/Service/ImportService.vue"),
        meta: {
            title: 'Service',
            // checkPrivilege: false,
            breadcrumb: [
                { name: "Service", link: "/Ticketing/ServiceListing" },
                { name: "ImportService" }
            ]
        },
    },

    {
        path: "/Ticketing/AddService/:id",
        name: "AddService",
        component: () => import("@/views/Service/AddService.vue"),
        meta: {
            title: 'Add Service',
            // checkPrivilege: false,
            breadcrumb: [
                { name: "Service", link: "/Ticketing/ServiceListing" },
                { name: "Manage" }
            ]
        }
    },
    {
        path: "/Ticketing/Publish/:id",
        name: "PublishService",
        component: () => import("@/views/Service/PublishService.vue"),
        meta: {
            title: 'Publish Service',
            // checkPrivilege: false,
            breadcrumb: [
                { name: "Service", link: "/Ticketing/ServiceListing" },
                { name: "Publish" }
            ]
        },

    },
    {
        path: "/Ticketing/EditService/:id",
        name: "EditService",
        component: () => import("@/views/Service/EditService.vue"),
        meta: {
            title: 'Edit Service',
            // checkPrivilege: false,
            breadcrumb: [
                { name: "Service", link: "/Ticketing/ServiceListing" },
                { name: "Edit Service" }
            ]
        },

    },
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
const DEFAULT_TITLE = 'Home';
router.beforeResolve((to, from, next) => {
    document.title = 'TALYGEN - ' + (to.meta.title || DEFAULT_TITLE);
    document.getElementById("divProgress1").style.display = 'none';
    document.getElementById("talygenfooter").style.display = 'block';
    if (to.path == '/callback' || to.path == '/unauthorized' || to.path == '/logoutcallback') {
        next()
    }
    else if (to.path == '/Ticketing/Dashboard') {
        router.push({ path: '/Dashboard/Ticketing' });
    }
    else {
        const ustore = useloggedUserInfo();
        let app = ustore.GetUserInfo;
        let usertype = app.UserType;
        if (app) {
            let isCheckPrivilege = true;
            if (to.meta.hasOwnProperty('checkPrivilege')) {
                isCheckPrivilege = to.meta.checkPrivilege;
            }

            if (isCheckPrivilege) {
                let controllerName = "", actionName = "";
                if(usertype == 'CL'  && to.name == 'CreateArticle'){
                    to.meta.breadcrumb = to.meta.breadcrumb.filter(item => item.name !== "Article")
                }
                if (to.meta.hasOwnProperty('actionName') && to.meta.hasOwnProperty('controllerName')) {
                    controllerName = to.meta.controllerName;
                    actionName = to.meta.actionName;
                    if(usertype == 'CL' && controllerName =='KnowledgeBase' && actionName == 'ManageArticle'){
                        to.meta.breadcrumb = to.meta.breadcrumb.filter(item => item.name !== "Article")
                    }
                }
                else {
                    let pathArray = to.path.split("/");
                    if (pathArray.length > 2) {
                        controllerName = pathArray[1];
                        actionName = pathArray[2];
                    } else {
                        controllerName = pathArray[0];
                        actionName = pathArray[1];
                    }
                }
                if (controllerName != "" && actionName != "") {
                    DataService.CheckPrivilege(`controller=${controllerName}&action=${actionName}`).then((value) => {
                        if (value.data)
                            next()
                        else
                            //common.methods.ShowAlert('Sorry, You are not authorized to access this section.', "warning", true, "Alert");
                            next('/unauthorized');
                    },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else {
                    next()
                }
            } else {
                next();
            }
        }
        else {
            authservice.authenticate(to.path).then(() => {
                console.log('authenticating a protected url:' + to.path);
                next();
            });
        }
    }
});
export default router

